import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import NavLink from 'components/shared/NavLink'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

// Images
import Pointer from 'img/location.inline.svg'

// Interface
import { PostsProps } from 'components/flex/Posts/Overview/Shell'

const Wrapper = styled.div`
  border-radius: 24px;
  mask-image: radial-gradient(white, black); // safari overflow fix
  @media (min-width: 992px) {
    height: 467px;
  }
  @media (max-width: 991px) {
    height: 367px;
  }
  @media (max-width: 767px) {
    height: 267px;
  }
  @media (max-width: 575px) {
    height: 250px;
  }
  @media (max-width: 375px) {
    height: 190px;
  }
`

const ImageWrapper = styled(motion.div)`
  bottom: 0;
  left: 0;
  width: 100%;
`

const Image = styled(Plaatjie)`
  border-radius: 24px;
`

const Excerpt = styled.div`
  bottom: 0;
  border-radius: 24px;
  background: linear-gradient(
    358deg,
    #03414e 21.46%,
    rgba(3, 65, 78, 0.6) 57.69%,
    rgba(242, 116, 0, 0) 88.39%,
    rgba(3, 65, 78, 0) 88.4%
  );
  color: ${({ theme }) => theme.color.light};

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 992px) {
      font-size: 25px;
      -webkit-line-clamp: 6;
    }
    @media (max-width: 991px) {
      font-size: 18px;
      -webkit-line-clamp: 4;
    }
    @media (max-width: 767px) {
      -webkit-line-clamp: 2;
    }
    @media (max-width: 375px) {
      font-size: 14px;
    }
  }

  & a > span {
    @media (max-width: 375px) {
      font-size: 14px !important;

      & svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    height: 75%;
  }
  @media (max-width: 767px) {
    height: 62.5%;
  }
  @media (max-width: 575px) {
    height: 50%;
  }
`

const Location = styled.div`
  & span {
    font-family: ${({ theme }) => theme.font.family.secondary};
  }
`

const Categories = styled.ul`
  & li {
    font-family: ${({ theme }) => theme.font.family.secondary};
  }
`

const Post: React.FC<PostsProps> = ({ post, fields }) => {
  const [isHover, setHover] = useState(false)

  return (
    <Wrapper className="position-relative overflow-hidden">
      <NavLink to={post.uri}>
        <motion.div
          role="button"
          onHoverStart={() => setHover(true)}
          onHoverEnd={() => setHover(false)}
          className="h-100"
        >
          <ImageWrapper className="h-100">
            <motion.div
              initial={{ scale: 1 }}
              animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
              exit={{ scale: 1 }}
              transition={{ duration: 0.25 }}
              className="h-100"
            >
              <Image image={post.recap.image} className="h-100" alt="" />
            </motion.div>
          </ImageWrapper>
          <Excerpt className="position-absolute d-flex flex-column justify-content-end p-3 w-100">
            {post.recap.location && (
              <Location className="d-flex align-items-center mb-3">
                <Pointer />
                <span className="ms-3">{post.recap.location.name}</span>
              </Location>
            )}
            <h2>{post.title}</h2>

            {post.projectcategories && (
              <Categories>
                {post.projectcategories.nodes.map((category: any) => (
                  <li>{category.name}</li>
                ))}
              </Categories>
            )}
            <div className="d-flex justify-content-end">
              <AnchorPrimary to={post.uri}>{fields.readmoretext}</AnchorPrimary>
            </div>
          </Excerpt>
        </motion.div>
      </NavLink>
    </Wrapper>
  )
}

export default Post
